import styled from "@emotion/styled";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const LayoutWrap = styled.div`
  position: relative;
`;

const Layout: React.FC = ({ children }) => {
  return (
    <LayoutWrap>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </LayoutWrap>
  );
};

export default Layout;
