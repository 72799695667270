import styled from "@emotion/styled";
import Section, {
  SectionDesc,
  SectionSubTitle,
  SectionTitle,
} from "../layout/Section";

const VideoWrap = styled.div`
  text-align: center;
  width: 56rem;
  margin: 0 auto;
  height: 31.5rem;
  box-shadow: ${({ theme }) => theme.shadow.normal};

  @media (max-width: 992px) {
    width: 90vw;
    height: 30rem;
  }
`;

const LocalProject: React.FC = () => {
  return (
    <Section color="sub" direction="column">
      <SectionTitle>SPECIAL : LOCAL PROJECT</SectionTitle>
      <SectionSubTitle>로컬 프로젝트 - 더몽 in 담양</SectionSubTitle>
      <SectionDesc>
        지방의 빈집을 활용하여, 육아에 지친 어머니들과 아이들에게 새로운 가치 문화를
        제공하는 시골하루 프로젝트 '엄마의 휴일'의 영상입니다. ------
      </SectionDesc>
      <VideoWrap>
        <iframe
          title="local-project-video"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/6sVRMZIhFFQ"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoWrap>
    </Section>
  );
};

export default LocalProject;
