import styled from "@emotion/styled";

const ContentWrap = styled.div`
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 20rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    padding-bottom: 24rem;
  }
`;

const MainWrap = styled.div``;

const Content: React.FC = ({ children }) => {
  return (
    <ContentWrap>
      <MainWrap>{children}</MainWrap>
    </ContentWrap>
  );
};

export default Content;
