import { css, Global } from "@emotion/react";

const style = css`
  @font-face {
    font-family: "SquareRound";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "IBM";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }

  body,
  html {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-size: 16px;
    width: 100%;
    height: auto;
    font-family: "SquareRound", "IBM", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
`;

const GlobalStyle: React.FC = () => {
  return <Global styles={style} />;
};

export default GlobalStyle;
