import styled from "@emotion/styled";
import Section, { SectionSubTitle, SectionTitle } from "../layout/Section";
import galleryImg1 from "../../resources/images/homepage/gallary1.jpg";
import galleryImg2 from "../../resources/images/homepage/gallary2.jpg";
import galleryImg3 from "../../resources/images/homepage/gallary3.jpg";
import galleryImg4 from "../../resources/images/homepage/gallary4.jpg";
import galleryImg6 from "../../resources/images/homepage/gallary6.jpg";
import galleryImg7 from "../../resources/images/homepage/gallary7.jpg";
import galleryImg8 from "../../resources/images/homepage/gallary8.jpg";
import galleryImg9 from "../../resources/images/homepage/gallary9.jpg";
import galleryImg10 from "../../resources/images/homepage/gallary10.jpg";

const ImageSection = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 1rem;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 0 0.875rem;
    height: auto;

    > img {
      flex: 1;
      position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 1.8rem;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 5px;
      transition: all 0.2s ease-out;
    }
  }

  @media (max-width: 768px) {
    > div:first-of-type,
    > div:last-of-type {
      display: none;
    }

    > div {
      width: 50%;
    }
  }
`;

const ImageDesc = styled.div`
  margin-bottom: 1.8rem;
  border-radius: 5px;
  width: 100%;
  min-height: 30rem;
  line-height: 1.8;
  background: ${({ theme }) => theme.colors.main};
  display: flex;
  letter-spacing: 1px;
  word-break: keep-all;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 2rem;
  word-break: keep-all;
  color: ${({ theme }) => theme.colors.base};
`;

const Gallery: React.FC = () => {
  return (
    <Section direction="column">
      <SectionTitle>GALLERY</SectionTitle>
      <SectionSubTitle>갤러리</SectionSubTitle>
      <ImageSection>
        <div>
          <img alt="gallary1" src={galleryImg1} />
          <img alt="gallary2" src={galleryImg2} />
        </div>
        <div>
          <img alt="gallary3" src={galleryImg3} />
          <img alt="gallary4" src={galleryImg4} />
          <img alt="gallary6" src={galleryImg6} />
        </div>
        <div>
          <img alt="gallary7" src={galleryImg7} />
          <ImageDesc>
            빈집은 모두가 낯선 문제 입니다. '후기 산업 사회'에서만 겪을 수 있는
            사회 문제이기 때문입니다. 조금씩 세상이 올바른 방향으로 나아가도록
            노력하는 더몽의 도전을 응원 부탁드립니다.
          </ImageDesc>
        </div>
        <div>
          <img alt="gallary8" src={galleryImg8} />
          <img alt="gallary9" src={galleryImg9} />
          <img alt="gallary10" src={galleryImg10} />
        </div>
      </ImageSection>
    </Section>
  );
};

export default Gallery;
