import styled from "@emotion/styled";

const Anchor = styled.div`
  display: block;
  visibility: hidden;
  height: 3rem;
  margin-top: -3rem;
`;

export default Anchor;
