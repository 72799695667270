import styled from "@emotion/styled";
import { darken, lighten } from "polished";
import LogoImgSrc from "../../resources/images/logo_horizontal.png";

const StyledHeader = styled.header`
  width: 100%;
  height: 6rem;
  position: fixed;
  z-index: 2;
  transition: height 0.2s ease 0s, background-color 0.3s ease 0s;
  backface-visibility: hidden;
  background: ${({ theme }) => theme.colors.base};
  box-shadow: ${({ theme }) => theme.shadow.header};
`;

const LogoImg = styled.img`
  width: 240px;
  height: 100px;
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1440px) {
    max-width: 1320px;
  }
`;

const LogoWrap = styled.a``;

const NavWrap = styled.nav`
  height: 100%;
`;

const MenuList = styled.ul`
  margin: 0;
  display: flex;
  list-style-type: none;
  height: 100%;
`;

const MenuItem = styled.li`
  width: auto;
  height: 100%;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  font-weight: bold;

  > a {
    cursor: pointer;
    transition: 0.3s;
    &:link,
    &:visited {
      color: ${({ theme }) => theme.colors.font.head};
      text-decoration: none;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.main};
    }

    &:active {
      color: ${({ theme }) => darken(0.1, theme.colors.main)};
    }
  }

  @media (max-width: 1200px) {
    display: none;

    &:last-of-type {
      display: flex;
    }
  }
`;

const Button = styled.button`
  padding: 0;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.base};
  font-size: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.main)};
  }

  &:active {
    border: none;
    outline: none;
    background: ${({ theme }) => darken(0.1, theme.colors.main)};
  }
`;

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Inner>
        <LogoWrap>
          <LogoImg src={LogoImgSrc} alt="더몽" />
        </LogoWrap>
        <NavWrap>
          <MenuList>
            <MenuItem>
              <a href="#home">Home</a>
            </MenuItem>
            <MenuItem>
              <a href="#about">About</a>
            </MenuItem>
            <MenuItem>
              <a href="#history">History</a>
            </MenuItem>
            <MenuItem>
              <a href="#gallery">Gallery</a>
            </MenuItem>
            <MenuItem>
              <a href="#articles">Articles</a>
            </MenuItem>
            <MenuItem>
              <a href="#contact">Contact</a>
            </MenuItem>
            <MenuItem>
              <a href="https://blog.naver.com/themonginterior">
                <Button>Blog</Button>
              </a>
            </MenuItem>
          </MenuList>
        </NavWrap>
      </Inner>
    </StyledHeader>
  );
};

export default Header;
