import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const SectionWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 6rem 0;
  overflow: hidden;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.font.content};
`;

export const SectionTitle = styled.div`
  font-size: 2.5rem;
  letter-spacing: -1px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.font.head};

  @media (max-width: 1200px) {
    font-size: calc(1rem + 1.5vw);
    margin-bottom: 2rem;
  }

  @media (max-width: 576px) {
    font-size: calc(0.3rem + 3vw);
  }
`;

export const SectionSubTitle = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.font.light};
  margin-bottom: 2rem;
`;

export const SectionDesc = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  padding: 0 2rem;
  word-break: keep-all;
`;

interface Props {
  id?: string;
  color?: string;
  direction?: string;
}

const Section: React.FC<Props> = ({
  id,
  children,
  color = "white",
  direction = "row",
}) => {
  const theme = useTheme();
  const backgroundColor =
    color === "sub" ? theme.colors.sub : theme.colors.base;
  const flexDirection = direction === "row" ? "row" : "column";

  return (
    <SectionWrap id={id} style={{ background: backgroundColor }}>
      <SectionContainer style={{ flexDirection }}>{children}</SectionContainer>
    </SectionWrap>
  );
};

export default Section;
