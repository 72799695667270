import styled from "@emotion/styled";
import { darken } from "polished";
import Inner from "../layout/Inner";
import Section, { SectionSubTitle, SectionTitle } from "../layout/Section";
import BlogImg1 from "../../resources/images/homepage/blog1.jpg";
import BlogImg2 from "../../resources/images/homepage/blog2.jpg";
import BlogImg3 from "../../resources/images/homepage/blog3.jpg";

const BlogWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const Blog = styled.a`
  width: 100%;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.base};
  box-shadow: ${({ theme }) => theme.shadow.normal};
  transition: 0.3s;

  &:link,
  &:visited {
    color: ${({ theme }) => theme.colors.font.head};
    text-decoration: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.main};
    box-shadow: ${({ theme }) => theme.shadow.small};
  }

  &:active {
    color: ${({ theme }) => darken(0.1, theme.colors.main)};
  }

  & + & {
    margin-left: 2rem;

    @media (max-width: 992px) {
      margin-left: 1rem;
    }
  }

  > img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    height: 80%;
    object-fit: cover;

    @media (max-width: 768px) {
      min-height: 30rem;
    }

    @media (max-width: 576px) {
      min-height: 90vw;
    }
  }

  > h2 {
    text-align: center;
  }

  > div {
    padding: 0 2rem;
    word-break: keep-all;
    display: flex;
    height: 5rem;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 1200px) {
      height: auto;
    }

    @media (max-width: 992px) {
      height: auto;
    }

    @media (max-width: 768px) {
      height: 5rem;
    }
  }

  @media (max-width: 768px) {
    max-width: 30rem;
    margin: 0 auto;

    & + & {
      margin: 0 auto;
      margin-top: 3rem;
    }
  }

  @media (max-width: 576px) {
    max-width: 90%;

    & + & {
      margin-top: 2rem;
    }
  }
`;

const Articles: React.FC = () => {
  return (
    <Section color="sub" direction="column">
      <SectionTitle>ARTICLES</SectionTitle>
      <SectionSubTitle>블로그 및 기사</SectionSubTitle>
      <Inner>
        <BlogWrap>
          <Blog href="https://blog.naver.com/PostView.nhn?blogId=themonginterior&logNo=222173268223">
            <img alt="blog1" src={BlogImg1} />
            <div>[PRESS] ‘kosme 기업나라’에 더몽이 소개되었습니다!</div>
          </Blog>
          <Blog href="https://blog.naver.com/PostView.nhn?blogId=themonginterior&logNo=222204835534">
            <img alt="blog2" src={BlogImg2} style={{ objectFit: "contain" }} />
            <div>[PRESS] 더몽 : 2020년 제6차 사회적 기업 인증!</div>
          </Blog>
          <Blog href="https://blog.naver.com/PostView.nhn?blogId=themonginterior&logNo=222177776397">
            <img alt="blog3" src={BlogImg3} />
            <div>[PROJECT] 방치된 공간을 되살리다. (in 담양)</div>
          </Blog>
        </BlogWrap>
      </Inner>
    </Section>
  );
};

export default Articles;
