import styled from "@emotion/styled";
import Inner from "../layout/Inner";
import Section, {
  SectionDesc,
  SectionSubTitle,
  SectionTitle,
} from "../layout/Section";
import { RiPhoneFill, RiMailFill, RiKakaoTalkFill } from "react-icons/ri";

declare global {
  interface Window {
    Email: any;
  }
}

const HorizontalDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: 2rem;
`;

const ContactGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% auto;

  @media (max-width: 992px) {
    grid-template-columns: auto;
  }
`;

const EmailFormWrap = styled.div`
  padding: 2rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;

  > span {
    text-align: right;
  }

  > div {
    margin-left: 1rem;
    text-align: left;
    display: flex;
    align-items: center;
  }

  div.icon {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const MapWrap = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.main};
  border-radius: 0.25rem;
  margin: 2rem;
  height: 25rem;

  > iframe {
    border-radius: 0.25rem;
  }

  @media (max-width: 992px) {
    height: 80vw;
  }
`;

function Contact() {
  return (
    <Section color="sub" direction="column">
      <SectionTitle>CONTACT</SectionTitle>
      <SectionSubTitle>문의하기</SectionSubTitle>
      <HorizontalDiv />
      <Inner>
        <ContactGrid>
          <EmailFormWrap>
            {/* <FormTitle>이메일 전송</FormTitle> */}
            <SectionDesc>
              새로운 프로젝트와 협업은 열린 마음으로 환영합니다. <br />
              언제든 편하게 연락주세요.
            </SectionDesc>
            <IconGrid>
              <div className="icon">
                <RiPhoneFill size="1.5rem" />
              </div>
              <div>+82-2-498-2375</div>
              <div className="icon">
                <RiMailFill size="1.5rem" />
              </div>
              <div>contact@themong.kr</div>
              <div className="icon">
                <RiKakaoTalkFill size="1.5rem" />
              </div>
              <div>eh263</div>
            </IconGrid>
          </EmailFormWrap>
          <MapWrap>
            <iframe
              title="google-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25309.496099180993!2d126.99282707019469!3d37.53887466075488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca484ade9ea7d%3A0x4b85b3dd150b1c4d!2zKOyjvCnrjZTrqr0!5e0!3m2!1sko!2skr!4v1610949255863!5m2!1sko!2skr"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{
                border: 0,
              }}
              allowFullScreen={false}
              aria-hidden="false"
              tabIndex={0}
            ></iframe>
          </MapWrap>
        </ContactGrid>
      </Inner>
    </Section>
  );
}

export default Contact;
