import styled from "@emotion/styled";
import Section, { SectionSubTitle, SectionTitle } from "../layout/Section";
import seoulsiImg from "../../resources/images/co-logos/seoul-si.png";
import seongdongguImg from "../../resources/images/co-logos/seongdong-gu.jpg";
import gangnamguImg from "../../resources/images/co-logos/gangnam-gu.jpg";
import seodaemoonImg from "../../resources/images/co-logos/seodaemoon.png";
import ventureImg from "../../resources/images/co-logos/venture.jpg";
import guktoImg from "../../resources/images/co-logos/gukto.jpg";
import socialImg from "../../resources/images/co-logos/social.png";
import seongdongSocialImg from "../../resources/images/co-logos/seongdong-social.jpg";
import skImg from "../../resources/images/co-logos/sk.jpg";
import mgImg from "../../resources/images/co-logos/mg.png";
import kaistImg from "../../resources/images/co-logos/kaist.jpg";
import kiboImg from "../../resources/images/co-logos/kibo.jpg";
import workWithImg from "../../resources/images/co-logos/work-with.png";
import seongdongDolbomImg from "../../resources/images/co-logos/seongdong-dolbom.jpg";
import seongdongSelfImg from "../../resources/images/co-logos/seongdong-self.jpg";
import koreanElecImg from "../../resources/images/co-logos/korean-elec.jpg";
import sinhyupImg from "../../resources/images/co-logos/sinhyup.jpg";
import wooriImg from "../../resources/images/co-logos/woori.jpg";
import socialVentureImg from "../../resources/images/co-logos/social-venture.png";
import sinnaImg from "../../resources/images/co-logos/sinna.png";
import funbilityImg from "../../resources/images/co-logos/funbility.png";
import deliveryLabImg from "../../resources/images/co-logos/deliverylab.png";
import careerTourImg from "../../resources/images/co-logos/career-tour.jpg";
import elGroundImg from "../../resources/images/co-logos/el-ground.jpg";
import hangbokImg from "../../resources/images/co-logos/hangbok.jpg";
import mindtripImg from "../../resources/images/co-logos/mindtrip.jpg";
import aqaraImg from "../../resources/images/co-logos/aqara.jpg";

const LogoContainer = styled.div`
  @media (max-width: 992px) {
    display: flex;
  }
`;

const LogosWrap = styled.div`
  width: 100%;
  padding: 0 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    padding: 2rem;
    width: calc(100% / 9);
    object-fit: contain;
    height: auto;
  }

  @media (max-width: 1440px) {
    padding: 0 5rem;

    > img {
      padding: 1.5rem;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 4rem;

    > img {
      padding: 1rem;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0rem;
    justify-content: space-between;

    > img {
      width: 100%;
      padding: 1rem 3rem;
    }
  }

  @media (max-width: 768px) {
    > img {
      padding: 1rem 2rem;
    }
  }

  @media (max-width: 576px) {
    > img {
      padding: 1rem 1rem;
    }
  }
`;

const Partners: React.FC = () => {
  return (
    <Section direction="column">
      <SectionTitle>PARTNERS & CLIENTS</SectionTitle>
      <SectionSubTitle>고객 및 협력사</SectionSubTitle>
      <LogoContainer>
        <LogosWrap>
          <img alt="서울시" src={seoulsiImg} />
          <img alt="성동구청" src={seongdongguImg} />
          <img alt="강남구청" src={gangnamguImg} />
          <img alt="서대문구청" src={seodaemoonImg} />
          <img alt="중소벤처기업부" src={ventureImg} />
          <img alt="국토교통부" src={guktoImg} />
        </LogosWrap>
        <LogosWrap>
          <img alt="한국사회적기업진흥원" src={socialImg} />
          <img alt="성동구사회적경제지원센터" src={seongdongSocialImg} />
          <img alt="SK" src={skImg} />
          <img alt="MG새마을금고" src={mgImg} />
          <img alt="KAIST" src={kaistImg} />
          <img alt="기술보증기금" src={kiboImg} />
          <img alt="함께일하는재단" src={workWithImg} />
        </LogosWrap>
        <LogosWrap>
          <img alt="성동돌봄센터" src={seongdongDolbomImg} />
          <img alt="서울성동지역자활센터" src={seongdongSelfImg} />
          <img alt="한국전력공사" src={koreanElecImg} />
          <img alt="신협" src={sinhyupImg} />
          <img alt="우리은행" src={wooriImg} />
          <img alt="소셜벤처허브" src={socialVentureImg} />
          <img alt="신나는조합" src={sinnaImg} />
        </LogosWrap>
        <LogosWrap>
          <img alt="펀빌리티" src={funbilityImg} />
          <img alt="딜리버리랩" src={deliveryLabImg} />
          <img alt="커리어투어" src={careerTourImg} />
          <img alt="엘그라운드" src={elGroundImg} />
          <img alt="행복나래" src={hangbokImg} />
          <img alt="마인드트립" src={mindtripImg} />
          <img alt="AQARA" src={aqaraImg} />
        </LogosWrap>
      </LogoContainer>
    </Section>
  );
};

export default Partners;
