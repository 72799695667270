import React from "react";
import About from "./components/home/About";
import Articles from "./components/home/Articles";
import Contact from "./components/home/Contact";
import Gallery from "./components/home/Gallery";
import History from "./components/home/History";
import Home from "./components/home/Home";
import LocalProject from "./components/home/LocalProject";
import Partners from "./components/home/Partners";
import Anchor from "./components/layout/Anchor";
import Layout from "./components/layout/Layout";

const App: React.FC = () => {
  return (
    <Layout>
      <Anchor id="home" />
      <Home />
      <Anchor id="about" />
      <About />
      <Anchor id="history" />
      <History />
      <LocalProject />
      <Anchor id="gallery" />
      <Gallery />
      <Anchor id="articles" />
      <Articles />
      <Partners />
      <Anchor id="contact" />
      <Contact />
    </Layout>
  );
};

export default App;
