import styled from "@emotion/styled";
import { AiFillFormatPainter } from "react-icons/ai";
import { BiCameraMovie, BiMapAlt, BiWindowOpen } from "react-icons/bi";
import { BsFillHouseFill } from "react-icons/bs";
import {
  GiHammerNails,
  GiMeditation,
  GiTalk,
  GiThreeFriends,
} from "react-icons/gi";
import { IoMdConstruct } from "react-icons/io";
import Inner from "../layout/Inner";
import Section, { SectionTitle, SectionSubTitle } from "../layout/Section";

const BusinessGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% auto;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

const BusinessItem = styled.div`
  word-break: keep-all;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  line-height: 1.8;

  > div {
    color: ${({ theme }) => theme.colors.font.head};
    display: flex;
    align-items: center;

    > a {
      margin-left: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      color: ${({ theme }) => theme.colors.main};
    }

    > svg {
      color: ${({ theme }) => theme.colors.main};
    }
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
`;

const About: React.FC = () => {
  return (
    <Section color="sub" direction="column">
      <SectionTitle>ABOUT</SectionTitle>
      <SectionSubTitle>진행 사업</SectionSubTitle>
      <Inner>
        <BusinessGrid>
          <BusinessItem>
            <div>
              <GiTalk size="2rem" />
            </div>
            <div>잘살기 살롱</div>동교동 소재의 폐한옥공간(1930년)을 활용한
            ‘취향’ 기반의 커뮤니티 살롱을 운영합니다. 현재 청년들이 겪는 주요
            이슈 ‘취직’, ‘연애’, ‘소확행’ 등의 핵심키워드를 중심으로 취향을
            공유할 수 있는 모임을 운영합니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <BsFillHouseFill size="2rem" />
            </div>
            <div>
              더몽 쉐어하우스
              <a href="http://friendsunit.co.kr/">
                <BiWindowOpen size="100%" />
              </a>
            </div>
            더몽의 모든 지점이 더몽 소유의 건물입니다. 직접매입 방식을 통해
            입주민의 주거 안정성을 확보합니다. 또한 건물 수리와 리모델링에
            비용을 아끼지 않습니다. 예쁜 인테리어와 대학가, 역세권의 공유주택을
            운영합니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <IoMdConstruct size="2rem" />
            </div>
            <div>'희망의 집수리' 사업</div>서울시 '희망의 집수리' 사업자로
            활동합니다. 저소득층/주거취약계층의 주거환경개선 공사를 진행합니다.
            2020년 27가구의 주거환경개선공사를 진행하였습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <GiMeditation size="2rem" />
            </div>
            <div>《마인드트립 x 더몽》</div>
            명상과 함께 마음으로 향하는 여행 '마인드트립'과 청년들을 위한
            명상클래스 '명상하고 앉아있네' 를 진행하였습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <GiThreeFriends size="2rem" />
            </div>
            <div>더몽 프렌즈유닛</div>친구/지인 그룹 단위로 입주하는 더몽만의
            시스템입니다. 셰어하우스의 장점은 그대로 살리고, 하우스메이트가 모두
            잘 알고 있던 친구이기에 눈치를 보지 않는 편안한 분위기 속에서 고유의
            라이프 스타일을 만들어갑니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <GiHammerNails size="2rem" />
            </div>
            <div>'가꿈주택' 사업</div>20년 이상 경과된 주택의 주거환경 개선
            공사를 진행합니다. 서울시 '가꿈주택'사업의 사업자로 활동 중이며
            서대문구 노후주택 시공사례를 바탕으로 '가꿈주택'사업의 우수사례로
            선정되었습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <BiCameraMovie size="2rem" />
            </div>
            <div>《팝업시네마X더몽: Private 한옥극장》</div>
            '모두를 위한 극장'과 팝업시네마를 진행하였습니다. 연인 혹은 친구들과
            함께, 특별한 우리만의 공간에서, 의미있는 시간을 보내고 싶은 분들을
            위해 준비했습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <BiMapAlt size="2rem" />
            </div>
            <div>
              밍글타운
              <a href="https://www.mingletown.co.kr">
                <BiWindowOpen size="100%" />
              </a>
            </div>
            '마음에 드는 쉐어하우스를 쉽게 찾는 방법은 없을까?' 내가 살고 싶은
            장소를 중심으로 가장 가까운 쉐어하우스부터 카드형태로 추천해주는
            쉐어하우스 플랫폼을 운영합니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <AiFillFormatPainter size="2rem" />
            </div>
            <div>소셜벤처/사회적기업을 위한 공간조성 공사</div>
            한국농수산식품유통공사의 '청년키움식당', 성동구 지역자활센터 카페
            '다온', 성동구 사회적경제지원센터 '성수맘푸드' 등 여러
            소셜벤처/사회적기업의 공간 조성 공사를 진행하였습니다.
          </BusinessItem>
        </BusinessGrid>
      </Inner>
    </Section>
  );
};

export default About;
