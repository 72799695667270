import { Theme, ThemeProvider } from "@emotion/react";
import React from "react";
import GlobalStyle from "./GlobalStyle";

const theme: Theme = {
  shadow: {
    header: "0 0 10px rgba(0,0,0,0.1);",
    small: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)",
    normal: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
    large: "0 1rem 3rem rgba(0, 0, 0, 0.175)",
  },
  colors: {
    main: "#c29082",
    sub: "#eef0e2",
    base: "#fff",
    accent: "#82C2B1",
    font: {
      head: "#333",
      content: "#555",
      light: "#6c757d",
    },
  },
};

const StyleProvider: React.FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

export default StyleProvider;
